<template>
     <div class="row">
            <div class="col-2"></div>
            <div class="col-10">
                <h4 class="mb-2 mt-3"><strong> Microphone Check</strong></h4>
                <h5 class="mb-2">This is an opportunity to check that your microphone is working correctly.</h5>
                <ol class="number mb-2 ml-4" >
                    <li>Make sure your headset is on and the microphone is in the downward position near your mouth.</li>
                    <li>When you are ready, click on the Record button and say "Testing, testing, one, two, three" into the microphone.</li>
                    <li>After you have spoken, click on the Stop button. Your recording is now complete.</li>
                    <li>Now click on the Playback button. You should clearly hear yourself speaking.</li>
                    <li>If you can not hear your voice clearly, please raise your hand.</li>
                </ol>
                <div class="offset-2 col-xl-3 col-lg-6 col-md-6 text-center microRecordDiv mt-4">
                    <h6><strong> Recorded Answer</strong></h6>
                    <p class="text-left mt-1" style="font-weight:600;">Current Status:</p>
                    <p class="text-left mt-1 mb-2">{{recordMessage}}</p>
                <!-- <div class="audio-container"><audio id='recordTest' :src="recordSrc" controls /></div> -->
                <!-- <app-CurtomRecorder ref="customRecorder"  :totalduration='.666'/> -->
                <app-CurtomPlayer ref="customPlayer" :recordSrc="recordSrc"/>
                <b-button :disabled="mickTesting.record" @click="beepFirst()" class="recordingBtn">Record</b-button>
                <b-button @click="recordPlayback()" :disabled="mickTesting.playback" class="recordingBtn">Playback</b-button>
                <b-button @click="recordStop()" :disabled="mickTesting.stop" class="recordingBtn">Stop</b-button>
                <div class="mt-3">
                <audio-recorder
                        :mic-failed="micCheck"
                        :before-recording="beforeRecording"
                        :after-recording="afterRecording"
                        :pause-recording="pauseRecording"
                        :time=".666"
                        class="invissible"
                        id="completeRecord"
                        />
                </div>
                </div>
                <ul class="square mt-3 mb-2 ml-4">
                    <li>During the test, you will not have Record, Playback and Stop buttons. The voice recording will start automatically.</li>
                </ul>
            </div>
            <audio id="beepAudio" src="@/assets/staticAudio/beep-07a.mp3" autostart="false" @ended="beepEnded('mickTesting')" ></audio>
        </div>
</template>
<script>
 import CurtomPlayer from '../reuseable/customAudioSlider'
 import $ from "jquery";
export default {
    components:{
                 'app-CurtomPlayer':CurtomPlayer,
    },
    data(){
        return{
            recordMessage:'Click Record to Begin',
            recordSrc:null,
            recordStatus:'beforeRecord',
            mickTesting:{
                record:false,
                playback:true,
                stop:true
            },
            recordControll:null,
        }
    },
    methods:{
         beforeRecording(){
          this.recordSrc=null
          //this.recordMessage='Press to stop Recording'
          //console.log('before Recording')
          //$('.ar-recorder__stop').addClass('upFront')
      },
      afterRecording(msg){
          //this.recordMessage='Listen Audio for Testing'
          //$('.ar-recorder__stop').removeClass('upFront')
          //console.log('after recording')
          this.recordSrc=window.URL.createObjectURL(msg.blob)
          this.$refs.customPlayer.src=this.recordSrc
      },
      pauseRecording(){
          $('.ar-recorder__stop').click()
      },
      micCheck(){

      },
      beepFirst(){
           var sound = document.getElementById("beepAudio");
           sound.playbackRate = 0.5;
            sound.play()
        var progressbar = document.getElementById("seekObj1");
            if(progressbar!=null) progressbar.style.marginLeft = "0px";
      },
      beepEnded(mod){
          if(mod=='mickTesting')
          {
              this.recordStarted()
          }
      },
      recordStarted(){
          this.recordSrc=null
          this.recordStatus='recording'
          this.recordMessage= 'Recording'
          this.mickTesting.record=true
          this.mickTesting.playback= true
          this.mickTesting.stop=false
          if(this.recordControll == null) this.recordControll=$('.ar-recorder > .ar-icon.ar-icon__lg')
          this.recordControll.trigger("click")
          //this.$refs.customRecorder.started()
      },
      recordPlayback(){
        //   var aud = document.getElementById("player2");
        //   aud.load()
        //   aud.play()
          this.$refs.customPlayer.audioPlay()
        //   this.$refs.customPlayer.ballSeek()
          this.recordMessage= 'Playing'
          this.mickTesting.record=true
          this.mickTesting.playback= true
          this.mickTesting.stop=false
          this.recordStatus='playing'
      },
      recordStop(){
          //console.log(this.recordStatus)
          if(this.recordStatus== 'recording'){
              $('.ar-recorder__stop').click()
              //this.$refs.customRecorder.stop()
          }
          else if(this.recordStatus== 'playing'){
            //   var aud = document.getElementById("player2");
            //     aud.pause()
            this.$refs.customPlayer.stopPlayer()
          }
          this.recordMessage= 'Complete'
            this.mickTesting.record=false
            this.mickTesting.playback= false
            this.mickTesting.stop= true
      }
    }
}
</script>