<template>
    <div class="row">
        <div class="col-12">
            <p class="offset-2 col-8 blackColor mt-4 mb-5 text-left"><strong>
                Look at the text below. In 40 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.
                </strong></p>
            <app-CurtomRecorder class="mt-5" ref="customRecorder" :weightTime="weightTime"  :totalduration="totalDuration"/>
            <p class="offset-2 col-8 questdesc blackColor mt-3 text-left">{{selectedquestion.readaloud_text}}</p>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import CurtomRecorder from '../../reuseable/customRecorderSlider'
const sdk = require("microsoft-cognitiveservices-speech-sdk");
export default {
    components:{
        'app-CurtomRecorder':CurtomRecorder,
    },
    props:{
        currentQuestion:{
            required:true,
            default:null,
            
        },
         mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            weightTime:40,
            totalDuration:40,
            fileName:'',
            microsoftTranscript:'',
            userAnswer:{
                mock_test_id:this.mockId,
                readaloud_id : 0,
                user_id : 0,
                user_recording: null,
                url:'',
                keywords:'',
                confidenceLevel:0,
                transcript:{
                    id:''
                }
            },
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'speakmodule/saveAnswer',
            getTranscripty:'speakmodule/getTranscripty',
            generateFileUpload:'speakmodule/generateFileUpload',
        }),
        ...mapGetters({
            answerResponse: 'speakmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.microsoftTranscript=''
            this.selectedquestion = question
            this.fileName='UserReadAloud--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.question_id+"_track"+Date.now()+'.wav'
            //this.fromMic()
        },
        async moveToNextRA(){
            
            this.userAnswer.transcript=this.selectedquestion.readaloud_text
            this.$refs.customRecorder.stop()
            this.saveUserAnswer()
            this.$refs.customRecorder.changeTiming(this.weightTime,this.totalDuration)
        },
        saveUserAnswer(){
             var file = new File([this.recordSrc], this.fileName);
            var fd = new FormData()
            fd.append('files', file,this.fileName)
            const filePath='audio/UserReadAloud/'+this.fileName.replace('--','')
             this.userAnswer.user_recording = filePath
             this.userAnswer.readaloud_id=this.selectedquestion.question_id
             this.generateFileUpload(fd).then(()=>{
                 if(this.answerResponse()=="Success"){
                    this.userAnswer.url='userreadaloud/createuserreadaloud'
                    this.saveAnswer(this.userAnswer).then(()=>{
                            //console.log(this.answerResponse().data)
                        })
                 }

             });
        },
        fromMic() {
            ////console.log('In Mic')
            const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
            let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "10000");
            this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            const phraseList = sdk.PhraseListGrammar.fromRecognizer(this.recognizer);
            phraseList.addPhrase(this.selectedquestion.transcript);
            //console.log('Speak into your microphone.');


            this.recognizer.startContinuousRecognitionAsync();

            this.recognizer.recognizing = (s, e) => {
                if(e!=null)
                {
                     //this.microsoftTranscript =e.result.text;
                    //console.log(`RECOGNIZING sub : Text=${e.result.text}`);
                }
            };

            this.recognizer.recognized = (s, e) => {
                ////console.log(e.result)
                    if (e.result.reason == 3) {
                     this.microsoftTranscript +=e.result.text;
                        //console.log(`RECOGNIZED final: Text=${e.result.text}`);
                        //this.checkRecordingPause()
                    }
                    else if (e.result.reason == 0) {
                        // this.$parent.moveToNextMK()
                        this.$parent.$parent.next()
                        //console.log("NOMATCH: Speech could not be recognized.");
                        this.recognizer.stopContinuousRecognitionAsync();
                    }
            };

            this.recognizer.canceled = (s, e) => {
                //console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason == 0) {
                    //console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    //console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    //console.log("CANCELED: Did you update the subscription info?");
                }

                this.recognizer.stopContinuousRecognitionAsync();
            };

            this.recognizer.sessionStopped = () => {
                ////console.log(s+e)
                //console.log("\n    Session stopped event.");
                this.recognizer.stopContinuousRecognitionAsync();
            };
            
        }
    },
    created(){
        this.userAnswer.user_id=this.$ls.get('user').user_id
        this.fileName='UserReadAloud--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.question_id+"_track"+Date.now()+'.wav'
    }
}
</script>
<style scoped>

.questdesc
{
  border: none!important;
  line-height: 1.8rem;
  position: relative;
  overflow-wrap: break-word;
  word-break: normal;
  background-color: white!important;
}
</style>