<template>
    <div class="row">
        <div class="col-12">
            <p class="col-12 blackColor mt-4 mb-5 text-left ml-3 mr-3 text-center "><strong>
                You will hear a recording. Click on the paragraph that best relates to the recording.
                </strong></p>
            <app-CustomPlayer v-if="audioOn" :noBeep='noBeep'  class="mt-5" ref="customPlayer" :weightTime='playerWeight' :audioSrc='selectedquestion.question_recording'/>
            </div>
        <div class="offset-2 col-8 text-left mt-3">
            <ol class="mt-3">
                <li v-for="(item, i) in answerLists" :key="item.option_id" class="mt-3">
                <label :id="item.option_id">
                    <input type="radio"
                    v-on:change="toggle(item)"
                    v-bind:checked="item.done">

                    <span v-if="item.done">
                    {{alphaList[i]}})  {{ item.option_desc }}
                    </span>
                    <span v-else>
                    {{alphaList[i]}})  {{ item.option_desc }}
                    </span>
                </label>
                </li>
            </ol>
         </div>
    </div>
</template>
<script>
import CustomPlayer from '../../reuseable/customAudioPlayerSlider'
import { mapActions,mapGetters } from 'vuex'
export default {
    components:{
        'app-CustomPlayer':CustomPlayer
    },
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        currentOptions:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            weightTime:0,
            totalDuration:30,
            playerWeight:7,
            audioOn:true,
            noBeep:true,
            score_count:0,
            answerLists:{
                type: Object,
                required: true
            },
            userAnswer:{
                url:'',
                question_id:0,
                option_id:0,
                user_score: 0,
                user_id:0,
                mock_test_id:this.mockId
            },
            currentAnswer:null,
            correctAnswer:null,
            alphaList:['A','B','C','D','E','F','G','H','I','J','K','L','M','N'],
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'listeningmodule/fetchQuestion',
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'listeningmodule/questions',
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.audioOn=true
            this.saveUserAnswer()
            // this.$parent.$parent.disableButton()
            this.selectedquestion = question
            this.$nextTick(function () {
                this.$refs.customPlayer.changeAudio(this.playerWeight,this.selectedquestion.question_recording)
                this.fetchAnswer(this.selectedquestion.question_id)
            })
        },
        fetchAnswer(questionId){
        //    this.questionservice('configlisteningsummaryoption/id='+questionId).then(()=>{
        //         this.answerLists = this.getQuestionList().data
        //         });
            this.answerLists=this.currentOptions.filter((item)=>{
                return item.question_id==questionId
            })
        },
        toggle: function(todo){
            for(var i = 0; i < this.answerLists.length; i++){
                this.answerLists[i].done = false;
            }
                todo.done = !todo.done
        },
        async checkAnswer(){
            //this.resetAll();
            var vm = this;
            vm.score_count=0
            vm.currentAnswer= vm.answerLists.filter((item)=>{
                return item.done == true
            })[0];

            vm.correctAnswer['done'] = true
            //$('#'+vm.correctAnswer['option_id']).addClass('rightAnswer')
            if(vm.currentAnswer['option_id']!= vm.correctAnswer['option_id']){
               // $('#'+vm.currentAnswer['option_id']).addClass('wrongSelection wrongAnswer')
               //console.log('nothing')
            }
            else{ 
               vm.score_count=1
                
            }
            vm.userAnswer.user_score ='Your Score: '+vm.score_count+'/1'
        },
        saveUserAnswer(){
            this.correctAnswer= this.answerLists.filter((item)=>{
                return item.correct_val_ind == "T"
            })[0];
           this.userAnswer.question_id= this.selectedquestion.question_id
           this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            })
            this.checkAnswer()
            ////console.log(this.currentAnswer);
            if(this.currentAnswer==null){
                    this.userAnswer.option_id =0
            }
            else {this.userAnswer.option_id = this.currentAnswer.option_id;}
            ////debugger
            this.userAnswer.url = 'userlisteningsummaryanswers/createanswer';
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                });
        }
    },
    created(){
        this.fetchAnswer(this.selectedquestion.question_id)
        this.userAnswer.user_id=this.$ls.get('user').user_id
    },
}
</script>
<style scoped>
textarea {
    height: 30vh;
    width: 100%;
    border: 1px solid #a9a9a9;
    resize: none;
    background: #fbfbfb;
    border-radius: 3px;
}
label {
    font-weight: 500;
    font-size: 13px!important;
}
</style>