<template>
    <div class="row">
        <div class="col-12">
            <p class="col-12 blackColor mt-4 mb-5 text-left ml-3 mr-3 text-center "><strong>
                Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.
                </strong></p>
            <app-CustomPlayer v-if="audioOn" :noBeep='noBeep'  class="mt-5" ref="customPlayer" :weightTime='playerWeight' :audioSrc='selectedquestion.question_recording'/>
            </div>
        <div class="offset-2 col-8 text-left mt-3">
            <p class="questionMark mt-4" style="padding-bottom: 5px !important;">{{selectedquestion.question_desc}}</p>
            <!-- <p>{{selectedquestion.option_desc}}</p> -->
            <ol class="mt-1">
                <li v-for="(item, i) in answerLists" :key="item.option_id" class="mt-3">
                <label :id="item.option_id">
                    <input type="checkbox" 
                    v-on:change="toggle(item)"
                    v-bind:checked="item.done">

                    <span v-if="item.done">
                    {{alphaList[i]}})  {{ item.option_desc }}
                    </span>
                    <span v-else>
                    {{alphaList[i]}})  {{ item.option_desc }}
                    </span>
                </label>
                </li>
            </ol>
         </div>
    </div>
</template>
<script>
import CustomPlayer from '../../reuseable/customAudioPlayerSlider'
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
export default {
    components:{
        'app-CustomPlayer':CustomPlayer
    },
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        currentOptions:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            weightTime:0,
            totalDuration:30,
            playerWeight:7,
            audioOn:true,
            noBeep:true,
            answerLists:{
                type: Object,
                required: true
            },
            options:null,
            currentAnswer:[],
            actualAnswer:null,
            userAnswer:{
                url:'',
                user_id:0,
                question_id:0,
                answers:0,
                user_score:0,
                mock_test_id:this.mockId
            },
            score_count:0,
            alphaList:['A','B','C','D','E','F','G','H','I','J','K','L','M','N'],
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'listeningmodule/fetchQuestion',
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'listeningmodule/questions',
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.audioOn=true
            // this.$parent.$parent.disableButton()
            this.saveUserAnswer()
            this.selectedquestion = question
            this.$nextTick(function () {
                this.$refs.customPlayer.changeAudio(this.playerWeight,this.selectedquestion.question_recording)
                this.fetchAnswer(this.selectedquestion.question_id)
            })
        },
        fetchAnswer(questionId){
            // this.questionservice('configlisteningmultioptions/id='+questionId).then(()=>{
            //         this.answerLists = this.getQuestionList().data
            //     });
            this.answerLists=this.currentOptions.filter((item)=>{
                return item.question_id==questionId
            })
        },
        toggle: function(todo){
            todo.done = !todo.done
        },
        async checkAnswer(){
            var vm = this;
            vm.score_count=0
            this.actualAnswer= this.answerLists.filter((item)=>{
                            return item.correct_val_ind == "T"
                        });
            this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            });
            $.each(vm.currentAnswer, function(){
                ////console.log(this.option_id)
                if(this.correct_val_ind == "T"){
                    vm.score_count++;
                    //$("#"+this.option_id+' span').addClass('rightAnswer')
                    this.done = true;
                }
                // else{
                //     $("#"+this.option_id + ' span').addClass('wrongAnswer')
                //     //vm.score_count--;
                // }
            })
            $.each(this.answerLists, function(){
                if(this.correct_val_ind == "T"){
                //$("#"+this.option_id).addClass('rightAnswer')
                vm.done = true
                }
            })
            //if(vm.score_count>2) vm.score_count=2
            vm.userAnswer.user_score ='Your Score: '+vm.score_count+'/'+ vm.actualAnswer.length 
            
            
        },
        saveUserAnswer(){

            this.userAnswer.question_id=this.selectedquestion.question_id
            this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            });
            this.checkAnswer()
            var vm = this;
            var index = 0;
            this.options = []
            $.each(this.currentAnswer, function(){
                const tempCt= {
                    option_id: vm.currentAnswer[index].option_id
                }
                index++;
                vm.options.push(tempCt);
            })
           // //console.log(this.currentAnswer);
            this.userAnswer.answers = this.options;
            this.userAnswer.url = 'userlisteningmutlianswer/createanswer';
            //console.log(this.userAnswer);
            //if(this.userAnswer.length!= undefined) {
                this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                });
            //}
        }
    },
    created(){
        this.fetchAnswer(this.selectedquestion.question_id)
        this.userAnswer.user_id=this.$ls.get('user').user_id
    },
}
</script>
<style scoped>
textarea {
    height: 30vh;
    width: 100%;
    border: 1px solid #a9a9a9;
    resize: none;
    background: #fbfbfb;
    border-radius: 3px;
}
label {
    font-weight: 500;
    font-size: 13px!important;
}
</style>