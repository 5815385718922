<template>
    <div class="row">
            <div class="col-2"></div>
            <div class="col-10">
                <h6 class="mb-2 mt-3"><strong>Read the prompt below. In 25 seconds, you must reply in your own words, as naturally and clearly as possible. You have 30 seconds to record your response. Your response will be sent together with your score report to the institutions selected by you.
                Please introduce yourself. For example, you could talk about one of the following.</strong></h6>
                <h5 class="mb-2">Please introduce yourself. For example, you could talk about one of the following.</h5>
                    <ul class="square mb-4 ml-4 pr-5">
                    <li>Your interests</li>
                    <li>Your plans for future study</li>
                    <li>Why you want to study abroad</li>
                    <li>Why you need to learn English</li>
                    <li>Why you chose this test</li>
                </ul>
               <app-CurtomRecorder class="mt-5" ref="customRecorder" :weightTime='25'  :totalduration='30'/>
            </div>
        </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import CurtomRecorder from '../reuseable/customRecorderSlider'
export default {
    components:{
        'app-CurtomRecorder':CurtomRecorder,
    },
    data(){
        return {
            recordSrc:null,
            user_mock_id:null,
            user_id:null,
            mockId:null
        }
    },
    methods:{
        ...mapActions({
            generateFileUpload:'speakmodule/generateFileUpload',
            updateUserMock:'usermocktest/saveMockForUser'
        }),
        ...mapGetters({
            answerResponse: 'speakmodule/AnswerResponse',
            userMockResponse:'usermocktest/UserMockResponse'
        }),
        saveUserIntroFile(){
            if(this.recordSrc!=null){
                ////debugger
                const user_mock_id=this.user_mock_id
                const user_id=this.user_id
                const mockId=this.mockId
                const fileName='userIntro--u'+user_id+'_q'+mockId+"_track"+Date.now()+'.wav'
                var file = new File([this.recordSrc], fileName);
                //this.recordedFile = file
                var fd = new FormData()
                fd.append('files', file,fileName)
                
                const filePath='audio/userIntro/'+fileName.replace('--','')

                this.generateFileUpload(fd).then(()=>{
                     if(this.answerResponse()=="Success"){
                        const data={
                                url:'usermocktest/updateusermocktestIntro',
                                user_mock_id:user_mock_id,
                                user_intro_path:filePath
                            }
                         this.updateUserMockTestIntro(data)
                     }
                })
            
            }
            // else{
            //     return this.$alert(
            //             "Application got an issue on recording please try again.",
            //             "Warning",
            //             "warning",
            //             {
            //                 confirmButtonText: "Cannot Proceed"
            //             })
            // }
        },
        updateUserMockTestIntro(data){
            this.updateUserMock(data).then(()=>{
                const response=this.userMockResponse()
                //console.log(response)
            })
        },
        weightOver(){
            // For temp I commented this code it will disable the next button
            // if(this.$parent.nextOff== undefined)
            // {
            //     this.$parent.$parent.nextOff=false
            // }
            // else{
            //     this.$parent.nextOff=false
            // }
            // //console.log(this.$parent.nextOff)
        },
        async moveToNext(){
            this.$refs.customRecorder.stop()
            this.saveUserIntroFile()
        }
    },
    created(){
        this.mockId= this.$ls.get('mockTestId')
        this.user_id=this.$ls.get('user').user_id
        this.user_mock_id=this.$ls.get('user_mock_id')
    }
}
</script>