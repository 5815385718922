<template>
    <div class="row">
        <div class="col-12">
            <p class="col-12 blackColor mt-4 mb-5 text-center ml-3 mr-3 "><strong>
                You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.
                </strong></p>
            <app-CustomPlayer v-if="audioOn" :noBeep='noBeep'  class="mt-5" ref="customPlayer" :weightTime='playerWeight' :audioSrc='selectedquestion.question_recording'/>
            </div>
        <div class="offset-2 col-8 text-left mt-5">
            <textarea spellcheck="false" data-gramm="false" rows="1" id='dictationAnswer' class="textAreaSingleLine m-3" v-model="inputText"></textarea>
         </div>
    </div>
</template>
<script>
import CustomPlayer from '../../reuseable/customAudioPlayerSlider'
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
export default {
    components:{
        'app-CustomPlayer':CustomPlayer
    },
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            weightTime:0,
            totalDuration:30,
            playerWeight:7,
            audioOn:true,
            noBeep:true,
            inputText:'',
            userAnswer:{
                url:'',
                user_id:0,
                question_id:0,
                answer_desc:'',
                mock_test_id:this.mockId,
                user_score:''
            },
            totalMarks:0,
            marksGot:0,
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.audioOn=true
            this.saveUserName()
            // this.$parent.$parent.disableButton()
            this.selectedquestion = question
            this.inputText=''
            this.$nextTick(function () {
                this.$refs.customPlayer.changeAudio(this.playerWeight,this.selectedquestion.question_recording)
            })
        },
        async checkAnswer(){
            this.totalMarks = 0
            this.marksGot = 0
            var answerWords = this.selectedquestion.transcript.split(' ');
            //console.log(this.selectedquestion)
            this.totalMarks = answerWords.length
            // if(this.inputText == '') return this.$alert(
            //                 "Please enter your answer!",
            //                 "Failed",
            //                 "error",
            //                 {
            //                     confirmButtonText: "OK"
            //                 })
            this.checkAnswerFlag = true
            var vm= this
            $.each(answerWords,function(index,item){
                //console.log(item)
                if(item=='.'|item==',') vm.marksGot+1
                else 
                {
                    item=item.replace('.','').replace(',','')
                    vm.inputText=vm.inputText.replace('.','').replace(',','')
                    vm.marksGot=(vm.inputText.trim().toLowerCase().includes(item.trim().toLowerCase()))? vm.marksGot+1: vm.marksGot;
                }
            })
        },
        saveUserName(){
            this.checkAnswer()
            this.userAnswer.question_id=this.selectedquestion.question_id
            this.userAnswer.answer_desc = this.inputText;
            this.userAnswer.user_score='Your Score: '+this.marksGot+'/'+this.totalMarks;
            this.userAnswer.url = 'userdectationanswer/createanswer';
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                });
        },
    },
    created(){
        this.userAnswer.user_id=this.$ls.get('user').user_id
    },
}
</script>
<style scoped>
.singleWords{
    display: inline-block;
    cursor: pointer;
    z-index: 9999;
}
.singleWords span{
    width: 5px;
    display: inline-block;
}
.selected{
    background: #ece149;
    color: red;
}
</style>