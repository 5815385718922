<template>
    <div class="row">
        <div class="offset-1 col-10">
     <div class="row">
         <div class="col-12 mb-2 mt-2">
            <p class="text-center">
                <strong>
                The text boxes in the below panel have been placed in a random order. Restore the original order by dragging the text boxes up and down and submit your final answer.
                 </strong></p>
         </div>
         <div class="col-12 mt-4 mb-2">
          <ul class="collection">
            <draggable :list="selectedquestion" class="dragArea" @end="onEnd">
            <li v-for="(task) in selectedquestion" :key="task.reorder_id" :id="task.reorder_id" class="collection-item">
            <strong>{{task.reorder_id}}) </strong>    
            {{task.reorder_desc}}
            </li>
            </draggable>
            </ul>
         </div>

     </div>
     </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import $ from 'jquery'
export default {
    components: {
            draggable,
        },
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            wordCount:0,
            inputText:'',
            score_count:0,
            correctAnswer:'',
            answerLists:{
                type: Object,
                required: true
            },
            userAnswer:{
                answers:null,
                url:'',
                user_id:0,
                question_id:0,
                user_score: 0,
                mock_test_id:this.mockId
            },
        }
    },
    methods:{
         ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'readingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
             answerResponse: 'readingmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.saveUserAnswer()
            this.selectedquestion = question
            var vm=this;
            $.each(this.selectedquestion,function(index,value){
                vm.correctAnswer += value.order_seq+', '
            })
        },
        toggle: function(todo){
                //console.log('Inside toggle')
                for(var i = 0; i < this.answerLists.length; i++){
                this.answerLists[i].done = false;
                }
                todo.done = !todo.done
        },
        onEnd(){
            if(this.checkAnswerFlag) this.checkAnswer()
        },
        async checkAnswer(){
            var vm = this;
            
            this.score_count=0
            //vm.removeClasses()
            //var seq = 1
            var correctAnswerArray= this.correctAnswer.split(',') 
            //vm.checkAnswerFlag = true
            var currentPara=false
            $('.collection li').each(function(index,item){
                const currentPosition = parseInt(item.id)
                ////console.log(currentPosition +'=='+correctAnswerArray[index])
                if(currentPosition == correctAnswerArray[index]){
                    //$('#'+item.id).addClass('rightAnswer')
                    if(currentPara){
                        vm.score_count++
                    }
                    currentPara = true
                }
                else{
                    //$('#'+item.id).addClass('wrongAnswer')
                    currentPara = false
                }
                //seq++
            })
                
                // if(vm.score_count < 2 ) this.currentClass ='text-danger'
                // else if(vm.score_count <=(this.selectedquestion.length-1)-1) this.currentClass ='text-warning' 
        },
        saveUserAnswer(){
            this.checkAnswer()
            this.userAnswer.question_id=this.selectedquestion[0].question_id // for question ID
            var orderSeq=[]
            var seq = 1
            $('.collection li').each(function(index,item){
                const currentPosition = parseInt(item.id)
                const tempCt= {
                    reorder_id: seq,
                    order_seq: currentPosition
                }
                orderSeq.push(tempCt);
                seq++
            });
            this.userAnswer.user_score = 'Your Score: '+ this.score_count + '/' + (this.selectedquestion.length-1);
            this.userAnswer.url = 'userreorderpara/createuserparagraph';
            this.userAnswer.answers = orderSeq;
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                });
        }
    },
    watch: {
        inputText: function (val) {
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    },
    created(){
        this.userAnswer.user_id=this.$ls.get('user').user_id
    },
}
</script>
<style scoped>
.collection{
    padding-left: 0;
    list-style-type: none;
    margin: .5rem 0 1rem 0;
    /* border: 1px solid #e0e0e0; */
    border-radius: 2px;
    overflow: hidden;
    position: relative;
}
.collection li{
    background-color: #f9f9f9;
    line-height: 1.5rem;
    padding: 15px 20px;
    margin: 0;
    border: 1px dashed #a4a1a1;
    margin-bottom: 20px;
    border-radius: 2px;
    cursor: grab;
    font-size: 12px;
}
.collection li:active, .collection li:focus{
    background-color: #ccc;
}
.collection li strong{
    padding-right:5px ;
}
.text-center button{
    margin-right:30px
}
</style>