<template>
    <div class="row">
        <div class="offset-1 col-10">
     <div class="row">
         <div class="col-12 mb-2 mt-2">
            <p class="text-left">
                <strong>
                 Read the passage below and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.
                 </strong></p>
         </div>
         <div class="col-12 mt-4 mb-2">
             <p class="text-left unselectable">{{selectedquestion.written_text}}</p>
         </div>
         <div class="col-12 mt-3 mb-2 text-center">
             <textarea  id='text' v-model="inputText" data-gramm="false" spellcheck="false"></textarea>
            <p class="text-left" style="padding-top: 5px !important;padding-left: 5px !important;"><strong>Word Count: {{wordCount}}</strong></p>
         </div>
     </div>
     </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
export default {
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            wordCount:0,
            inputText:'',
            userAnswer:{
                written_text_id:0,
                user_id:0,
                user_answer:'',
                url:'',
                api_result:null,
                mock_test_id:this.mockId,
            },
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'writtingmodule/saveAnswer'
        }),
        ...mapGetters({
            answerResponse: 'writtingmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.saveUserAnswer()
            this.selectedquestion = question
            this.inputText=''
        },
        saveUserAnswer(){
                this.userAnswer.written_text_id=this.selectedquestion.question_id
                this.userAnswer.user_answer=this.inputText
                this.userAnswer.api_result = ''
                this.userAnswer.url = 'userwrittensummery/createusersummery'
                this.resultStatus='AI-Result Ready'
                this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse().data)
                });
        }
    },
    watch: {
        inputText: function (val) {
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    },
    created(){
        this.userAnswer.user_id=this.$ls.get('user').user_id
    }
}
</script>
<style scoped>
textarea {
    height: 20vh;
    width: 100%;
    border: 1px solid #a9a9a9;
    resize: none;
    background: #fbfbfb;
    border-radius: 3px;
}
</style>