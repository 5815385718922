<template>
    <div class="row">
        <div class="col-12 text-center">
            <p class="col-12 blackColor mt-4 mb-3 text-left"><strong>
                Look at the graph below. In 25 seconds, please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.
                </strong></p>
                <div class="row">
                    <div class="col-lg-8 col-md-12 text-center">
                        <img :src="selectedquestion.graph" />
                    </div>
                    <div class="col-lg-4 col-md-12 text-center">
                        <app-CurtomRecorder id="imageRecorder" class="mt-2" ref="customRecorder" :convert="converSpeech" :weightTime="weightTime"  :totalduration="totalDuration"/>

                    </div>
                </div>
                
        </div>
    </div>
</template>
<script>
import CurtomRecorder from '../../reuseable/customRecorderSlider'
import { mapActions,mapGetters } from 'vuex'
const sdk = require("microsoft-cognitiveservices-speech-sdk");
// import $ from "jquery";
export default {
    components:{
        'app-CurtomRecorder':CurtomRecorder,
    },
    props:{
        currentQuestion:{
            required:true,
            default:null,
            
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            weightTime:25,
            totalDuration:40,
            converSpeech:true,
            fileName:'',
            userAnswer:{
                user_id:0,
                graph_id:0,
                recording:null,
                mock_test_id:this.mockId,
                 transcript:''
            }
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'speakmodule/saveAnswer',
            getTranscripty:'speakmodule/getTranscripty',
            generateFileUpload:'speakmodule/generateFileUpload',
        }),
        ...mapGetters({
            answerResponse: 'speakmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.selectedquestion = question
            this.fileName='UserDescribeImage--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.question_id+"_track"+Date.now()+'.wav'
            //this.fromMic()
        },
        async moveToNextRA(){
            await this.$refs.customRecorder.stop()
            this.saveUserAnswer()
            await this.$refs.customRecorder.changeTiming(this.weightTime,this.totalDuration)
        },
        saveUserAnswer(){
             var file = new File([this.recordSrc], this.fileName);
            var fd = new FormData()
            fd.append('files', file,this.fileName)
            const filePath='audio/UserDescribeImage/'+this.fileName.replace('--','')
             this.userAnswer.recording = filePath
             this.userAnswer.graph_id=this.selectedquestion.question_id
             this.generateFileUpload(fd).then(()=>{
                 if(this.answerResponse()=="Success"){
                     this.userAnswer.url='usergraphrecording/createrecording'
                    this.saveAnswer(this.userAnswer).then(()=>{
                        this.userAnswer.transcript='';
                            //console.log(this.answerResponse().data)
                    })
                } 
            });
        },
        fromMic() {
            ////console.log('In Mic')
            const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
            let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "10000");
            this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            const phraseList = sdk.PhraseListGrammar.fromRecognizer(this.recognizer);
            phraseList.addPhrase(this.selectedquestion.transcript);
            //console.log('Speak into your microphone.');

            this.recognizer.startContinuousRecognitionAsync();

            this.recognizer.recognizing = (s, e) => {
                if(e!=null)
                {
                     //this.microsoftTranscript =e.result.text;
                    //console.log(`RECOGNIZING sub : Text=${e.result.text}`); 
                }
            };

            this.recognizer.recognized = (s, e) => {
                ////console.log(e.result)
                    if (e.result.reason == 3) {
                     this.userAnswer.transcript +=e.result.text;
                        //console.log(`RECOGNIZED final: Text=${e.result.text}`);
                    }
                    else if (e.result.reason == 0) {
                        // this.$parent.moveToNextMK()
                        //this.$parent.$parent.next()
                        //console.log("NOMATCH: Speech could not be recognized.");
                        this.recognizer.stopContinuousRecognitionAsync();
                    }
            };

            this.recognizer.canceled = (s, e) => {
                //console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason == 0) {
                    //console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    //console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    //console.log("CANCELED: Did you update the subscription info?");
                }

                this.recognizer.stopContinuousRecognitionAsync();
            };

            this.recognizer.sessionStopped = () => {
                ////console.log(s+e)
                //console.log("\n    Session stopped event.");
                this.recognizer.stopContinuousRecognitionAsync();
            };
            
        }
    },
    created(){
        this.userAnswer.user_id=this.$ls.get('user').user_id
        this.fileName='UserDescribeImage--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.question_id+"_track"+Date.now()+'.wav'
        //this.fromMic()
    }
}
</script>
<style scoped>
img{
width: 70%;
text-align: center;
margin: 0 auto;
display: block;
/* height: 350px; */
}

</style>
<style>
#imageRecorder .extrapadding
{
    padding-right: 15px!important;
    padding-left: 15px!important;
}
</style>