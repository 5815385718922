<template>
    <div class="row">
        <div class="offset-1 col-10">
     <div class="row">
         <div class="col-12 mb-2 mt-2">
            <p class="text-center">
                <strong>
                 In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.
                 </strong></p>
         </div>
         <div class="col-12 mt-4 mb-2">
             <p class="questdesc" v-html="selectedquestion.question_desc"></p>
         
         <div class="mt-2" id="qlist2">
                <div :id="'dragitem'+item.answer_id+'-container'" class="drag-container" v-for="item in answerLists" :key="item.answer_id">
                <div :id="'dragitem'+item.answer_id" class="qitem2 answerDiv" >
                    {{item.answer_desc}}
                </div>
                </div>
            </div>
         </div>

     </div>
     </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';
export default {
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        currentOptions:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            wordCount:0,
            inputText:'',
            answerLists:{
                type: Object,
                required: true
            },
            options:[],
            correctAnswer:null,
            score_count:0,
             userAnswer:{
                user_id:0,
                question_id:0,
                answers:null,
                url:'',
                user_score:0,
                mock_test_id:this.mockId,
            },
        }
    },
    methods:{
         ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'readingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
            answerResponse: 'readingmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.saveUserAnswer()
            this.selectedquestion = question
            this.changeText()
            this.fetchAnswer(this.selectedquestion.question_id)
        },
        toggle: function(todo){
                //console.log('Inside toggle')
                for(var i = 0; i < this.answerLists.length; i++){
                this.answerLists[i].done = false;
                }
                todo.done = !todo.done
        },
        fetchAnswer(questionId){
            this.userAnswer.question_id =questionId
            this.answerLists =this.currentOptions.filter((item)=>{
                return item.question_id==questionId
            })
            this.$nextTick(()=>{
                this.makedraggable()
                this.droping()
            })
            
        //    this.questionservice('configblankoptions/id='+questionId).then(()=>{
        //         this.answerLists = this.getQuestionList().data
        //    }).then(()=>{
        //         this.makedraggable()
        //         this.droping()
        //     })
        },
        makedraggable() {
            $(".qitem2").draggable({
                    "revert": "invalid"
            });
        },
        droping(){
             $(".droppable-element").droppable({
                "accept": ".qitem2",
                "drop": function(event, ui) {
                    if ($(this).find(".qitem2").length) {
                        var $presentChild = $(this).find(".qitem2"),
                            currChildId = $presentChild.attr("id"),
                            $currChildContainer = $("#" + currChildId + "-container");                  
                    $currChildContainer.append($presentChild);
                    $presentChild.removeAttr("style");
                    //this.makedraggable();
                     $(".qitem2").draggable({
                            "revert": "invalid"
                    });
                    }
                    
                    $(ui.draggable).clone().appendTo(this).removeAttr("style");
                    $(ui.draggable).remove();
                
                }
            })
            $.ui.draggable.prototype.destroy;// = function (ul, item) { };
        },
        changeText(){
            if(this.selectedquestion.question_desc!=null) {
                for(var index = 1; index <= 20; index++){
               this.selectedquestion.question_desc = this.selectedquestion.question_desc.replace('#blank' + index + '#', '<div class="droppable-element" style="width:150px;height:30px;display:inline-block;border:1px solid #ccc;margin: 2px 5px 0px 5px;border-radius: 3px;background: #f9f9f9;color:black;"></div>')     
                }
            }
            
        },
        async checkAnswer(){
            var vm = this;
            //this.checkAnswerFlag = true;
            this.score_count = 0;
            //vm.removeClasses()
            vm.correctAnswer = vm.answerLists.filter(function(item){
                return item.correct_val_ind == 'T'
            })
            const correct = vm.correctAnswer
            $('.droppable-element').each(function(index,item){
                ////console.log(index)
                ////console.log($(item).find('div'))
                if($(item).find('div').length >0){
                    if(correct[index].answer_desc != item.childNodes[0].innerText)
                    {
                        //console.log('nothing')
                        //$(item).addClass('wrongSelection wrongAnswer')
                    }
                    else{
                        vm.score_count++
                        //$(item).addClass('rightSection rightAnswer')
                    }
                    ////console.log(item.childNodes[0].innerText)
                }
                // else{
                //      $(item).addClass('wrongSelection')
                // }
            })

            
            vm.userAnswer.user_score ='Your Score: '+vm.score_count+'/'+ vm.correctAnswer.length;
            ////console.log(vm.userAnswer.user_score)
        },
        saveUserAnswer(){
            this.checkAnswer()
            var vm = this;
            $('.droppable-element').each(function(index,item){
                if($(item).find('div').length >0){
                    const tempCt= {
                    answer_desc: item.childNodes[0].innerText
                }
                vm.options.push(tempCt);
                }
            });
            this.userAnswer.url = 'userblankanswers/createblankanswer';
            this.userAnswer.answers = this.options;
             this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                });
        }
    },
    created(){
        this.changeText()
        this.fetchAnswer(this.selectedquestion.question_id)
        this.userAnswer.user_id=this.$ls.get('user').user_id
    },
    watch: {
        inputText: function (val) {
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    },
}
</script>
<style scoped>
p{
    line-height: 25px;
}
#qlist2
{
    padding: 10px 19px;
    border: 1px solid #ccc;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #f9f9f9;
}
.drag-container
{
    display: inline;
}
.answerDiv
{
    display: inline-block;
    padding-top: 5px;
    padding-right: 7px;
    padding-bottom: 5px;
    margin-top: 5px!important;
    padding-left: 7px;
    cursor: pointer;
    min-height: 25px;
    background: #3eb2e3;
    margin: 0px 5px;
    position: relative;
    top: 0;
    left: 0;
    color: white;
    border-radius: 3px;
}
.droppable-element .answerDiv
{
    background: none;
    padding: 0 1px;
    color: black;
        line-height: 10px;
}

.text-center button{
    margin-right:30px
}
li.rightAnswer
{
    list-style: decimal;
}
.questdesc
{
    margin-bottom: 0px;
    border: 1px solid #ccc;
    background: white;
    border-bottom: 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    line-height: 35px!important;
}
</style>