<template>
    <div class="row">
        <div class="offset-1 col-10">
     <div class="row">
         <div class="col-12 mb-2 mt-2">
            <p class="text-center">
                <strong>
                 There are some words missing in the following text. Please select the correct word in the drop-down box.
                 </strong></p>
         </div>
         <div class="col-12 mt-4 mb-2">
              <p class="questdesc" v-html="selectedquestion.question_para"></p>
         </div>

     </div>
     </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';
export default {
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        currentOptions:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            wordCount:0,
            score_count:0,
            correctAnswer:null,
            inputText:'',
            answerLists:{
                type: Object,
                required: true
            },
            options:[],
            userAnswer:{
                user_id:0,
                question_id:0,
                answers:null,
                url:'',
                user_score: 0,
                mock_test_id:this.mockId,
            },
            //question_id,user_id,answer_desc,user_result,mock_test_id
        }
    },
    methods:{
         ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'readingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
            answerResponse: 'readingmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.saveUserAnswer()
            this.selectedquestion = question
            if(this.selectedquestion!=null)this.fetchAnswer(this.selectedquestion.question_id)
        },
        changeText(){
            //considering less than 20 fill in balnks
            for(var i =1;i<20;i++){
            var replaceVariable='#blank'+i+'#'
            this.selectedquestion.question_para = this.selectedquestion.question_para.replaceAll(replaceVariable,'<span id="select'+i+'"></span>')
            }
        },
        fetchAnswer(questionId){
           this.changeText()
           this.answerLists =this.currentOptions.filter((item)=>{
                return item.question_id==questionId
            })
            this.$nextTick(()=>{
                 var loopEnd = false
                var iteration = 1
                while(!loopEnd){
                    const currentOPtions = this.answerLists.filter(function (item) {
                    return item.blank_id == iteration;
                    })
                    //console.log(currentOPtions)
                     var selectList = "<select style='margin:0px 5px;' name='numbers' id='dropdown"+iteration+"'>";
                        if(currentOPtions.length<=0)
                        {
                            loopEnd = true
                        }
                        else{
                            selectList += '<option value="0"></option>';
                            $.each(currentOPtions, function (i) {
                                const value= currentOPtions[i].option_id
                                const text = currentOPtions[i].option_desc
                                 selectList += '<option value="'+value+'">'+text+'</option>';
                            });
                            selectList += "</select>";
                            //console.log($('#select'+iteration))
                            $('#select'+iteration).append(selectList)
                        }
                    iteration++
                }
            })
            // this.questionservice('configfillingblanksqrw/id='+questionId).then(()=>{
            // this.answerLists = this.getQuestionList().data
            // //console.log(this.answerLists)
            // }).then(()=>{
            //     var loopEnd = false
            //     var iteration = 1
            //     while(!loopEnd){
            //         const currentOPtions = this.answerLists.filter(function (item) {
            //         return item.blank_id == iteration;
            //         })
            //         //console.log(currentOPtions)
            //          var selectList = "<select style='margin:0px 5px;' name='numbers' id='dropdown"+iteration+"'>";
            //             if(currentOPtions.length<=0)
            //             {
            //                 loopEnd = true
            //             }
            //             else{
            //                 selectList += '<option value="0"></option>';
            //                 $.each(currentOPtions, function (i) {
            //                     const value= currentOPtions[i].option_id
            //                     const text = currentOPtions[i].option_desc
            //                      selectList += '<option value="'+value+'">'+text+'</option>';
            //                 });
            //                 selectList += "</select>";
            //                 //console.log($('#select'+iteration))
            //                 $('#select'+iteration).append(selectList)
            //             }
            //         iteration++
            //     }
            // })

        },
        async checkAnswer(){
            var vm = this;
            //this.checkAnswerFlag = true;
            this.score_count = 0;
            //vm.removeClasses()
            vm.correctAnswer = vm.answerLists.filter(function(item){
                return item.correct_val_ind == 'T'
            })
            const correct = vm.correctAnswer
            ////console.log(correct)
            $('select').each(function(index,item){
                ////console.log(item.options.selectedIndex)
                ////console.log(item.options[item.options.selectedIndex].text)
                if(item.options.selectedIndex >0){
                    const itemText = item.options[item.options.selectedIndex].text
                    if(correct[index].option_desc != itemText)
                    {
                        //console.log('nothing')
                        //$(item).addClass('wrongSelection wrongAnswer')
                    }
                    else{
                        vm.score_count++;
                        //$(item).addClass('rightSection rightAnswer')
                    }
                     const tempCt= {
                        answer_desc: itemText
                    }
                    vm.options.push(tempCt);
                }
                else{
                    const tempCt= {
                        answer_desc: 'Not Selected'
                    }
                    vm.options.push(tempCt);
                     //$(item).addClass('wrongSelection')
                }
            })

            vm.userAnswer.user_score ='Your Score: '+vm.score_count+'/'+ vm.correctAnswer.length ;

        },
        saveUserAnswer(){
            this.checkAnswer()
            this.userAnswer.question_id=this.selectedquestion.question_id
            // var vm=this
            //  $('select').each(function(index,item){
            //     if(item.options.selectedIndex >0){
            //         const tempCt= {
            //             answer_desc: item.options[item.options.selectedIndex].text
            //         }
            //         vm.options.push(tempCt);
            //     }
            // });
            this.userAnswer.url = 'userrwblankanswers/createblankanswer';
            this.userAnswer.answers = this.options;
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                });
        }
       
    },
    created(){
        this.fetchAnswer(this.selectedquestion.question_id)
        this.userAnswer.user_id=this.$ls.get('user').user_id
    },
    watch: {
        inputText: function (val) {
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    },
}
</script>
<style scoped>
p{
line-height: 25px;
}
.text-center button{
    margin-right:30px
}
li.rightAnswer
{
    list-style: decimal;
}
.questdesc
{
    line-height: 30px;
background: none;
border-style: solid;
border-color: #ccc;
border-radius: 3px;
margin-top: 5px;
line-height: 35px!important;
    overflow: scroll;
    margin-bottom: 30px;
}

</style>