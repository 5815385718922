<template>
    <div class="row">
        <div class="col-12 ml-5 mr-5">
     <div class="row">
         <div class="col-12 mb-2 mt-2">
            <p class="text-center">
                <strong>
                 Read the text and answer the question by selecting all the correct responses. More than one response is correct.
                 </strong></p>
         </div> 
         <div class="col-lg-7 col-md-12 mt-4 mb-2">
             <p class="questdesc">{{selectedquestion.msc_desc}}</p>
         </div>
         <div class="col-lg-5 col-md-12 mt-3 mb-2 text-left">
             <p class="questionMark">{{selectedquestion.msc_question}}</p>
            <div id="answerList">
                <ol class="mt-3">
                    <li v-for="item in answerLists" :key="item.msc_id" :id="item.mcsc_id" class="mt-3">
                    <label>
                        <input type="checkbox"
                        v-on:change="toggle(item)"
                        v-bind:checked="item.done"
                        :key="item.msc_id">

                        <span v-if="item.done">
                        <p style="display:inline"> {{ item.mcsc_options }}</p>
                        </span>
                        <span v-else>
                        <p style="display:inline"> {{ item.mcsc_options }} </p>
                        </span>
                    </label>
                    </li>
                </ol>
            </div>
        </div>
     </div>
     </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
export default {
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        currentOptions:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            wordCount:0,
            score_count: 0,
            inputText:'',
            currentAnswer: null,
            correctAnswer:null,
            total_mark:0,
            answerLists:{
                type: Object,
                required: true
            },
            userAnswer:{
                user_id:0,
                multicall:'T',
                answers:'',   
                url:'',
                user_score: 0,
                mock_test_id:this.mockId,
            },
        }
    },
    methods:{
         ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'readingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
            answerResponse: 'readingmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.saveUserAnswer()
            this.selectedquestion = question
             $.each(this.answerLists, function(){
                this.done = false
            });
            this.fetchAnswer(this.selectedquestion.mcs_id)
        },
        toggle: function(todo){
            todo.done = !todo.done
        },
        fetchAnswer(questionId){
        //    this.questionservice('configmultiplechoicechild/id='+questionId).then(()=>{
        //     this.answerLists = this.getQuestionList().data
        //         //console.log(this.answerLists)
        //     })
        this.answerLists =this.currentOptions.filter((item)=>{
                return item.question_id==questionId
            })
        },
        async checkAnswer(){
            var vm = this;
            this.total_mark=0
            this.score_count=0
            // this.checkAnswerFlag = true;
            this.correctAnswer= this.answerLists.filter((item)=>{
                return item.correct_val_ind == "T" & item.done == false
            })
            this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            })
            $.each(this.currentAnswer, function(){
                if(this.correct_val_ind == "T"){
                    //$("#"+this.mcsc_id).addClass('rightAnswer')
                    vm.total_mark++
                    vm.score_count++
                }
                else{
                    //$("#"+this.mcsc_id).addClass('wrongAnswer')
                    vm.score_count--
                }
            })
            $.each(this.correctAnswer, function(){
                //$("#"+this.mcsc_id).addClass('rightAnswer')
                vm.total_mark++
                this.done = true
            })
                vm.userAnswer.user_score ='Your Score: '+vm.score_count+'/'+ vm.total_mark
        },
        saveUserAnswer(){
            this.checkAnswer();
            this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            })
            var options=[]
            const userId=this.userAnswer.user_id
            var vm=this
            var index = 0;
            $.each(this.currentAnswer, function(){
                const tempCt= {
                    user_id: userId,
                    mcs_id: vm.currentAnswer[index].mcs_id,
                    mcsc_id: vm.currentAnswer[index].mcsc_id
                }
                index++;
                options.push(tempCt);
            })
            this.userAnswer.answers = options;
            this.userAnswer.url = 'usermultiplechoiceanswer/createanswer';
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    vm.userAnswerResponse = this.answerResponse().data
                });
        }
    },
    created(){
        this.fetchAnswer(this.selectedquestion.mcs_id)
        this.userAnswer.user_id=this.$ls.get('user').user_id
    },
    watch: {
        inputText: function (val) {
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    },
}
</script>
<style scoped>
label {
    font-weight: 500;
    font-size: 13px;
}
.questdesc{
    overflow: scroll;
    margin-bottom: 30px;
}
</style>