<template>
    <div class="row">
        <div class="col-12">
            <p class="offset-2 col-8 blackColor mt-4 mb-5 text-left"><strong>
                You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.
                </strong></p>
            <app-CustomPlayer v-if="audioOn" :noBeep='noBeep'  class="mt-5" ref="customPlayer" :weightTime='playerWeight' :audioSrc='selectedquestion.recording'/>
            <app-CurtomRecorder v-else class="mt-5" ref="customRecorder" :noBeep='noBeep' :weightTime="weightTime"  :totalduration="totalDuration"/>
        </div>
    </div>
</template>
<script>
import CurtomRecorder from '../../reuseable/customRecorderSlider'
import CustomPlayer from '../../reuseable/customAudioPlayerSlider'
import { mapActions,mapGetters } from 'vuex'
const sdk = require("microsoft-cognitiveservices-speech-sdk");
export default {
    components:{
        'app-CurtomRecorder':CurtomRecorder,
        'app-CustomPlayer':CustomPlayer
    },
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
         mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            weightTime:0,
            totalDuration:30,
            playerWeight:3,
            audioOn:true,
            count:0,
            noBeep:true,
            fileName:'',
            microsoftTranscript:'',
            userAnswer:{
                user_id:0,
                recording_id:0,
                recording:null,
                keywords:'',
                confidenceLevel:0,
                mock_test_id:this.mockId,
                 transcript:{
                    id:''
                },
            }
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'speakmodule/saveAnswer',
            getTranscripty:'speakmodule/getTranscripty',
            generateFileUpload:'speakmodule/generateFileUpload',
        }),
        ...mapGetters({
            answerResponse: 'speakmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.audioOn=true
            // this.$parent.$parent.disableButton()
            this.selectedquestion = question
            this.fileName='UserRepeatSentence--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.question_id+"_track"+Date.now()+'.wav'
            this.$nextTick(function () {
                this.$refs.customPlayer.changeAudio(this.playerWeight,this.selectedquestion.recording)
            })
        },
        moveToNextRA(){
            if(!this.audioOn)
            {
                 this.count++
                this.$nextTick(function () {
                this.$refs.customRecorder.stop();
                this.saveUserAnswer()
                })
            }
            //await this.$refs.customRecorder.changeTiming(this.weightTime,this.totalDuration)
        },
        fromMic() {
            ////console.log('In Mic')
            const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
            let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "10000");
            this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            const phraseList = sdk.PhraseListGrammar.fromRecognizer(this.recognizer);
            phraseList.addPhrase(this.selectedquestion.transcript);
            //console.log('Speak into your microphone.');


            this.recognizer.startContinuousRecognitionAsync();

            this.recognizer.recognizing = (s, e) => {
                if(e!=null)
                {
                     //this.microsoftTranscript =e.result.text;
                    //console.log(`RECOGNIZING sub : Text=${e.result.text}`);
                }
            };

            this.recognizer.recognized = (s, e) => {
                ////console.log(e.result)
                    if (e.result.reason == 3) {
                     this.microsoftTranscript +=e.result.text;
                        //console.log(`RECOGNIZED final: Text=${e.result.text}`);
                    }
                    else if (e.result.reason == 0) {
                        // this.$parent.moveToNextMK()
                        //this.$parent.$parent.next()
                        //console.log("NOMATCH: Speech could not be recognized.");
                        this.recognizer.stopContinuousRecognitionAsync();
                    }
            };

            this.recognizer.canceled = (s, e) => {
                //console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason == 0) {
                    //console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    //console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    //console.log("CANCELED: Did you update the subscription info?");
                }

                this.recognizer.stopContinuousRecognitionAsync();
            };

            this.recognizer.sessionStopped = () => {
                ////console.log(s+e)
                //console.log("\n    Session stopped event.");
                this.recognizer.stopContinuousRecognitionAsync();
            };
            
        },
        audioFinish(){
            this.audioOn = false
            // this.$parent.$parent.disableButton()
            if(this.count>0)
            {
                this.$nextTick(function () {
                this.$refs.customRecorder.changeTiming(this.weightTime,this.totalDuration)
                })
            }
        },
        saveUserAnswer(){
            this.userAnswer.transcript=this.microsoftTranscript
             var file = new File([this.recordSrc], this.fileName);
            var fd = new FormData()
            fd.append('files', file,this.fileName)
            const filePath='audio/UserRepeatSentence/'+this.fileName.replace('--','')
             this.userAnswer.recording = filePath
             this.userAnswer.recording_id=this.selectedquestion.question_id
             this.generateFileUpload(fd).then(()=>{
                 if(this.answerResponse()=="Success"){
                     this.userAnswer.url='userrepeatsentence/createuserrepeatsentence'
                    this.saveAnswer(this.userAnswer).then(()=>{
                            //console.log(this.answerResponse().data)
                    })
                } 
            });
        }
    },
    created(){
        this.userAnswer.user_id=this.$ls.get('user').user_id
        this.fileName='UserRepeatSentence--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.question_id+"_track"+Date.now()+'.wav'
        
    }
}
</script>
<style scoped>

.questdesc
{
  border: none!important;
  line-height: 1.8rem;
  position: relative;
  overflow-wrap: break-word;
  word-break: normal;
  background-color: white!important;
}
</style>