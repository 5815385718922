<template>
    <div class="row">
        <div class="offset-1 col-10">
     <div class="row">
         <div class="col-12 mb-2 mt-2">
            <p class="text-left">
                <strong>
                 You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.
                 </strong></p>
         </div>
         <div class="col-12 mt-4 mb-2">
             <p class="text-left unselectable">{{selectedquestion.question_desc}}</p>
         </div>
         <div class="col-12 mt-3 mb-2 text-center">
             <textarea id='text' v-model="inputText" data-gramm="false" spellcheck="false"></textarea>
            <p class="text-left" style="padding-top: 5px !important;padding-left: 5px !important;"><strong>Word Count: {{wordCount}}</strong></p>
         </div>
     </div>
     </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
export default {
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            wordCount:0,
            inputText:'',
            userAnswer:{
                question_id:0,
                user_id:0,
                essay_desc:'',
                url:'',
                api_result:null,
                mock_test_id:this.mockId,
                user_result:''
            },
            //question_id,essay_desc,user_id,api_result,mock_test_id,user_result
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'writtingmodule/saveAnswer'
        }),
        ...mapGetters({
            answerResponse: 'writtingmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.saveUserAnswer()
            this.selectedquestion = question
            this.inputText=''
        },
        saveUserAnswer(){
                this.userAnswer.question_id=this.selectedquestion.question_id
                this.userAnswer.essay_desc=this.inputText
                this.userAnswer.api_result = ''
                this.userAnswer.url = 'useressayanswer/createuseressay'
                this.resultStatus='AI-Result Ready'
                this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse().data)
                });
        }
    },
    watch: {
        inputText: function (val) {
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    },
    created(){
        this.userAnswer.user_id=this.$ls.get('user').user_id
    }
}
</script>
<style scoped>
textarea {
    height: 40vh;
    width: 100%;
    border: 1px solid #a9a9a9;
    resize: none;
    background: #fbfbfb;
    border-radius: 3px;
}
</style>