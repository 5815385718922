<template>
    <div class="row">
        <div class="col-12 mt-5 text-center">
            <h2 class="mb-2 mt-3 text-center"><strong> Congratulation</strong></h2>
            <h3 class="mb-2" v-if="endModuleValue==''">You finished your mock test.</h3>
            <h3 class="mb-2" v-else>You finished your section mock test.</h3>
            <div class="text-center" style="margin: auto 0;" v-if="endModuleValue==''">
                <ul class=" mt-4 number mb-4 text-left" style="display: inline-block;">
                    <li v-if="user_type=='premium'">Results will be ready with in 30 minutes.</li>
                    <li  v-if="user_type=='premium'">You can view your results by clicking a result button next to attempted mock test once its done</li>
                    <li  v-if="user_type=='premium'">Your last three mock test results also presented on Study Center &gt; Exam history section.</li>
                    <li v-if="user_type!='premium'"><strong>Subscribe to the Premium Package now to see your scores!</strong></li>
                    </ul>
            </div>
            <div class="text-center" style="margin: auto 0;" v-if="endModuleValue!=''">
                <ul class=" mt-4 number mb-4 text-left" style="display: inline-block;">
                    <li v-if="user_type=='premium'">You can review your results by clicking review button in section mock test module.</li>
                    </ul>
            </div>
</div></div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
export default {
    props:['user_mock_id','mockId','user_id','endModule'],
    data(){
        return{
            user_type:'',
            endModuleValue:''
            //mockId:this.user_mock_id
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'mocktest/fetchQuestion',
            userMockSave:'usermocktest/saveMockForUser',
        }),
        ...mapGetters({
            getQuestionList: 'mocktest/questions',
            user_MockResponse:'usermocktest/UserMockResponse'
        }),
        updateUserMockTestStatus(){
           const data={
                    url:'usermocktest/updateusermocktestStatus',
                    user_mock_id:this.user_mock_id,
                    mock_status:'Complete',
                    user_id:this.user_id,
                    mock_test_id:this.mockId
                    }
            this.userMockSave(data).then(()=>{
                const response=this.user_MockResponse()
                this.saveMockTest()
                //console.log(response)
            })
        },
        saveMockTest(){
            this.questionservice('mocktestresult/testId='+this.user_mock_id).then(()=>{
                var response=this.getQuestionList
                //console.log(response)
            });
        }
    },
    created(){
        $('.footerMock button').remove()
        $('.footerMock #nextButton').remove()
        $('.placeInline').remove()
        this.updateUserMockTestStatus()
        this.user_type=this.$ls.get('user').user_type;
        this.endModuleValue=this.endModule

    }
}
</script>