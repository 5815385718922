<template>
    <div class="row">
        <div class="col-12">
            <p class="col-12 blackColor mt-4 mb-5 text-left ml-3 mr-3 "><strong>
                You will hear a short report. Write a summary for a fellow student who was not present. You should write 50-70 words. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.
                </strong></p>
            <app-CustomPlayer v-if="audioOn" :noBeep='noBeep'  class="mt-5" ref="customPlayer" :weightTime='playerWeight' :audioSrc='selectedquestion.question_recording'/>
            </div>
        <div class="offset-1 col-10 text-center mt-5">
             <textarea id='text' data-gramm="false" v-model="inputText" spellcheck="false"></textarea>
            <p class="text-left" style="padding-top: 5px !important;padding-left: 5px !important;"><strong>Word Count: {{wordCount}}</strong></p>
         </div>
    </div>
</template>
<script>
import CustomPlayer from '../../reuseable/customAudioPlayerSlider'
import { mapActions,mapGetters } from 'vuex'
export default {
    components:{
        'app-CustomPlayer':CustomPlayer
    },
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            weightTime:0,
            totalDuration:30,
            playerWeight:12,
            audioOn:true,
            noBeep:true,
            wordCount:0,
            inputText:'',
            userAnswer:{
                url:'',
                user_id:0,
                question_id:0,
                answer_desc:'',
                api_result:null,
                mock_test_id:this.mockId,
                user_result:''
            }
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.audioOn=true
            this.saveUserAnswer()
            // this.$parent.$parent.disableButton()
            this.selectedquestion = question
            this.inputText=''
            this.$nextTick(function () {
                this.$refs.customPlayer.changeAudio(this.playerWeight,this.selectedquestion.question_recording)
            })
        },
        saveUserAnswer(){
            this.userAnswer.question_id=this.selectedquestion.question_id
            this.userAnswer.answer_desc = this.inputText;
            this.userAnswer.api_result = ''
            this.userAnswer.url = 'UserSpokenAnswer/createspokenanswer';
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse().data)
                });
        }
    },
    watch: {
        inputText: function (val) {
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    },
    created(){
        this.userAnswer.user_id=this.$ls.get('user').user_id
    }
}
</script>
<style scoped>
textarea {
    height: 20vh;
    width: 100%;
    border: 1px solid #a9a9a9;
    resize: none;
    background: #fbfbfb;
    border-radius: 3px;
}
</style>