<template>
    <div class="row">
        <div class="col-12">
            <p class="col-12 blackColor mt-4 mb-5 text-center ml-3 mr-3 "><strong>
                You will hear a recording. Type the missing words in each blank.
                </strong></p>
            <app-CustomPlayer v-if="audioOn" :noBeep='noBeep'  class="mt-5" ref="customPlayer" :weightTime='playerWeight' :audioSrc='selectedquestion.question_recording'/>
            </div>
        <div class="offset-1 col-10 text-left mt-5">
            <p class="mt-3" v-html="selectedquestion.question_desc"></p>
         </div>
    </div>
</template>
<script>
import CustomPlayer from '../../reuseable/customAudioPlayerSlider'
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
export default {
    components:{
        'app-CustomPlayer':CustomPlayer
    },
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            weightTime:0,
            totalDuration:30,
            playerWeight:7,
            audioOn:true,
            noBeep:true,
            answerLists:{
                type: Object,
                required: true
            },
            options:[],
            actualAnswer:null,
            score_count:0,
            correctAnswer:null,
            userAnswer:{
                user_id:0,
                question_id:0,
                answers:null,
                url:'',
                user_score: 0,
                mock_test_id:this.mockId
            },
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'listeningmodule/fetchQuestion',
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'listeningmodule/questions',
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.audioOn=true
            this.saveUserAnswer()
            // this.$parent.$parent.disableButton()
            this.selectedquestion = question
            this.$nextTick(function () {
                this.$refs.customPlayer.changeAudio(this.playerWeight,this.selectedquestion.question_recording)
                this.fetchAnswer(this.selectedquestion.question_id)
                this.changeText()
            })
        },
        changeText(){
            for(var index = 1; index <=10; index++)
            {
                this.selectedquestion.question_desc = this.selectedquestion.question_desc.replace('#blank' + index + '#', '<input type="text" class="element" style="width:150px;height:25px;display:inline-block;border:1px solid #ccc;margin:0 5px"/>')
            }
        },
        async checkAnswer(){
            this.score_count=0
            //$('.showAnswer').remove()
            // if(this.checkAnswerFlag)
            // {
            var vm = this;
            //this.checkAnswerFlag = true;

            vm.correctAnswer = vm.answerLists.filter(function(item){
                return item.correct_val_ind == 'T'
            });
            //$('.element').addClass('wrongSelection wrongAnswer')
            ////console.log(vm.correctAnswer)
            $('.element').each(function(index,item){
                //console.log(vm.correctAnswer[index].blank_option)
                //console.log(item.value)
                if(vm.correctAnswer[index].blank_option.trim().toLowerCase() != item.value.trim().toLowerCase()){
                    //$(item).addClass('wrongSelection wrongAnswer')
                    //console.log('nothing')
                    // $(item).after(()=>{
                    //     return "<span class='showAnswer'> ( Answer:- " + vm.correctAnswer[index].blank_option.trim() + ")</span>"
                    // })
                }
                else{
                    //$(item).removeClass('wrongSelection wrongAnswer')
                    vm.score_count++
                }
            });

            this.userAnswer.user_score ='Your Score: '+vm.score_count+'/'+ vm.actualAnswer.length
           // }
           
        },
        saveUserAnswer(){
            this.checkAnswer()
            this.userAnswer.question_id=this.selectedquestion.question_id
            var vm=this
            $('.element').each(function(index,item){
                    //console.log(item)
                    const tempCt= {
                    answer_desc: item.value
                }
                vm.options.push(tempCt);
            });
            this.userAnswer.url = 'configlisteningblankanswers/createanswer';
            this.userAnswer.answers = this.options;
            if(this.userAnswer.user_score==0)
            {
                this.userAnswer.user_score ='Your Score: '+this.score_count+'/'+ this.actualAnswer.length
            }
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                });
        },
        fetchAnswer(questionId){
            this.questionservice('configlisteningblankoptions/id='+questionId).then(()=>{
                this.answerLists = this.getQuestionList().data
                this.actualAnswer = this.answerLists
                });
        }
    },
    created(){
        this.fetchAnswer(this.selectedquestion.question_id)
        this.changeText()
        this.userAnswer.user_id=this.$ls.get('user').user_id
    }
}
</script>
<style scoped>
textarea {
    height: 30vh;
    width: 100%;
    border: 1px solid #a9a9a9;
    resize: none;
    background: #fbfbfb;
    border-radius: 3px;
}
</style>