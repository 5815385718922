<template>
    <div class="row">
        <div class="col-12">
            <p class="col-12 blackColor mt-4 mb-5 text-center ml-3 mr-3 "><strong>
                You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker said. Please click on the words that are different.
                </strong></p>
            <app-CustomPlayer v-if="audioOn" :noBeep='noBeep'  class="mt-5" ref="customPlayer" :weightTime='playerWeight' :audioSrc='selectedquestion.question_recording'/>
            </div>
        <div class="offset-1 col-10 text-left mt-5">
            <div class="singleWords" v-for="(item,i) in splitPara" :key="i">
                <div class="singleWords" @click="selectWord(i)" :id="i" >{{item}}</div>
                <span v-if="i < splitPara.length">&nbsp;</span>
            </div>
         </div>
    </div>
</template>
<script>
import CustomPlayer from '../../reuseable/customAudioPlayerSlider'
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
export default {
    components:{
        'app-CustomPlayer':CustomPlayer
    },
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            weightTime:0,
            totalDuration:30,
            playerWeight:7,
            audioOn:true,
            noBeep:true,
            splitPara:null,
            correctAnswer:null,
            totalMarks:0,
            marksGot:0,
            userAnswer:{
                url:'',
                user_id:0,
                question_id:0,
                answers:null,
                mock_test_id:this.mockId,
                user_score:''
            }
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'listeningmodule/fetchQuestion',
            saveAnswer: 'listeningmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'listeningmodule/questions',
            answerResponse: 'listeningmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.audioOn=true
            this.saveUserAnswer()
            // this.$parent.$parent.disableButton()
            this.selectedquestion = question
            this.$nextTick(function () {
                this.$refs.customPlayer.changeAudio(this.playerWeight,this.selectedquestion.question_recording)
                this.changeText()
            })
        },
        changeText(){
            this.splitPara = this.selectedquestion.question_para.split(' ')
            $('.singleWords').removeClass('selected')
        },
        selectWord(divId){
            $('#'+divId).removeClass("wrongWord")
            $('#'+divId).toggleClass('selected')
        },
        async checkAnswer(){
            ////console.log(this.checkAnswerFlag)
            $('.correctAnswer').remove()
            // if(!this.checkAnswerFlag)
            // {
            //      $('.correctAnswer').remove()
            //      $('.wrongWord').removeClass('wrongWord')
            // }
            // else
            // {
            var questionPara_Array=this.selectedquestion.question_para.split(' ');
            var correctPara_Array=this.selectedquestion.correct_para.split(' ');
            var incorrectWords=[]
            var selectedWords=[]
            this.correctAnswer=[]
            this.totalMarks = 0
            this.marksGot = 0
            //this.checkAnswerFlag = true
            const vm = this
            $('.selected').each(function(index,item){
                ////console.log(index)
                selectedWords.push(item.innerText.trim())
            })
            $.each(questionPara_Array,function(index,item){
                if(item != correctPara_Array[index]){
                    // if(!$('#'+index).hasClass('selected'))
                    // {
                    //     $('#'+index).addClass("wrongWord")
                    //     $('#'+index).after(function(){
                    //         return '<span class="singleWords correctAnswer"> '+correctPara_Array[index]+' </span>'
                    //     })
                    // }
                    vm.correctAnswer.push(correctPara_Array[index])
                    incorrectWords.push(item)
                }
            })
            $.each(selectedWords,function(index,item){
                const matched=incorrectWords.filter(function(val){
                    return val==item
                })[0]
                vm.marksGot= (matched != null)? vm.marksGot+1:vm.marksGot-1;
            })
            this.totalMarks = this.correctAnswer.length;
            this.userAnswer.user_score='Your Score: '+this.marksGot+'/'+ this.totalMarks 
            //}
        },
        saveUserAnswer(){
            this.checkAnswer()
            this.userAnswer.question_id=this.selectedquestion.question_id
            var options = [];
            $('.selected').each(function(index,item){
                const tempCt= {
                    answer_desc: item.innerHTML
                }
                options.push(tempCt);
            });
             this.userAnswer.answers = options;
             this.userAnswer.url = 'confighighlightincorrectwordsanswer/createanswer';
            //console.log(options.length);
            if(options.length >0) {
                this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                });
            }
        }
    },
    created(){
        this.changeText()
        this.userAnswer.user_id=this.$ls.get('user').user_id
    }
}
</script>
<style scoped>
.singleWords{
    display: inline-block;
    cursor: pointer;
    z-index: 9999;
}
.singleWords span{
    width: 5px;
    display: inline-block;
}
.selected{
    background: #ece149;
    color: red;
}
</style>