<template>
    <div class="row">
        <div class="col-12 ml-5 mr-5">
     <div class="row">
         <div class="col-12 mb-2 mt-2">
            <p class="text-center">
                <strong>
                 Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.
                 </strong></p>
         </div>
         <div class="col-lg-7 col-md-12 mt-4 mb-2">
             <p class="questdesc">{{selectedquestion.msc_desc}}</p>
         </div>
         <div class="col-lg-5 col-md-12 mt-3 mb-2 text-left">
             <p class="questionMark">{{selectedquestion.msc_question}}</p>
            <ol class="mt-4 ml-2">
                <li v-for="item in answerLists" :key="item.mcs_id" :id="item.mcsc_id" class="mt-3">
                <label>
                    <input type="radio"
                    v-on:change="toggle(item)"
                    v-bind:checked="item.done"
                    name="readMcqSingle">
                    <span v-if="item.done">
                    <p style="display:inline">{{ item.mcsc_options }}</p>
                    </span>
                    <span v-else>
                    <p style="display:inline">{{ item.mcsc_options }}</p>
                    </span>
                </label>
                </li>
            </ol>
        </div>
     </div>
     </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
export default {
    props:{
        currentQuestion:{
            required:true,
            default:null,
        },
        currentOptions:{
            required:true,
            default:null,
        },
        mockId:{
            required:true,
            default:0
        }
    },
    data(){
        return{
            selectedquestion:this.currentQuestion,
            wordCount:0,
            inputText:'',
            answerLists:{
                type: Object,
                required: true
            },
            currentAnswer: null,
            correctAnswer:null,
            score_count:0,
            userAnswer:{
                mcs_id:0,
                user_id:0,
                mcsc_id:0,
                url:'',
                user_score:0,
                multicall:'F',
                mock_test_id:this.mockId,
            },
        }
    },
    methods:{
         ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'readingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
            answerResponse: 'readingmodule/AnswerResponse'
        }),
        changePresentQuestion(question){
            this.saveUserAnswer()
            this.selectedquestion = question
            this.fetchAnswer(this.selectedquestion.mcs_id)
        },
        toggle: function(todo){
                //console.log('Inside toggle')
                for(var i = 0; i < this.answerLists.length; i++){
                this.answerLists[i].done = false;
                }
                todo.done = !todo.done
        },
        fetchAnswer(questionId){
            this.userAnswer.mcs_id =questionId
            this.answerLists =null
            // this.questionservice('configmultiplechoicechild/id='+questionId).then(()=>{
            // this.answerLists = this.getQuestionList().data
            // //console.log(this.answerLists)
            //  })
            this.answerLists =this.currentOptions.filter((item)=>{
                return item.question_id==questionId
            })
        },
        checkAnswer(){
            var vm = this;
            vm.score_count=0
            vm.correctAnswer= vm.answerLists.filter((item)=>{
                return item.correct_val_ind == "T"
            })[0];
            vm.currentAnswer= vm.answerLists.filter((item)=>{
                return item.done == true
            })[0];
            
            vm.correctAnswer['done'] = true
            //$('#'+vm.correctAnswer['mcsc_id']).addClass('rightAnswer')
            if(vm.currentAnswer['mcsc_id']!= vm.correctAnswer['mcsc_id']){
                //$('#'+vm.currentAnswer['mcsc_id']).addClass('wrongAnswer')
                //console.log('nothing')
            }
            else{ 
                vm.score_count=1
                vm.userAnswer.user_score ='Your Score: 1/1';
            }
            if(vm.userAnswer.user_score ==0)
            {
                vm.userAnswer.user_score ='Your Score: 0/1';
            }
            
        },
        saveUserAnswer(){
            this.checkAnswer()
            this.currentAnswer= this.answerLists.filter((item)=>{
                return item.done == true
            })[0]
            if(this.currentAnswer == null | this.currentAnswer =='') {
               this.userAnswer.mcsc_id=0
            }
            else{
                this.userAnswer.mcsc_id = this.currentAnswer['mcsc_id'];
            }
            this.userAnswer.url = 'usermultiplechoiceanswer/createanswer';
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                });
        }
    },
    created(){
        this.fetchAnswer(this.selectedquestion.mcs_id)
        this.userAnswer.user_id=this.$ls.get('user').user_id
    },
    watch: {
        inputText: function (val) {
        var regex = /\s+/gi;
            this.wordCount =(val.length>0)? val.trim().replace(regex, ' ').split(' ').length:0;
        
        },
    },
}
</script>
<style scoped>
label {
    font-weight: 500;
    font-size: 13px;
}
.questdesc{
    overflow: scroll;
    margin-bottom: 30px;
}
</style>