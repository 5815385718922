<template>
    <div class="row">
        <div class="col-12" v-if="questionType=='speaking-writing'">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-10">
                    <ol class="noStyle mt-5 mb-4 ml-4" >
                        <li>You are about to begin {{line1}}</li>
                        <li>Time allowed : {{line2}}</li>
                        <li>Remember : to put your headphones on before beginning this section</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="col-12" v-if="questionType=='break'">
            <div class="row">
            <div class="col-2"></div>
                <div class="col-10">
                    <h4 class="mb-2 mt-3"><strong>No more Optional Break</strong></h4>
                    <ol class="noStyle mt-1 mb-4 ml-4" >
                        <li>10 Minute optional break removed from new formate</li>
                        <li>Click 'Next' when you are ready to begin</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
   
</template>
<script>
export default {
    props:{
        questionType:{
            default:'speaking-writing',
            required:true
        }
    },
    data(){
        return{
            line1:'Part 1 of the exam : Speaking and Writing',
            line2:'54 - 67 minutes'
        }
    },
}
</script>
<style scoped>
ol.noStyle {
  list-style-type:none;
}
</style>